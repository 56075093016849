<div class="d-flex flex-column justify-content-center align-items-center mcp-card">
  <div class="d-flex flex-column mcp-card-content-container">
    <div class="d-flex justify-content-between">
      <div style="flex: 1 1 auto;">

        <div class="mcp-card-content-container__title">
          <ng-container *ngIf="hasBackButton">
            <mcp-back-button class="back-button" (click)="goBack()"></mcp-back-button>
          </ng-container>
          <span class="back-button-title" *ngIf="title; else customTitleTemplate">{{ title | transloco }}</span>
          <ng-template #customTitleTemplate>
            <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
          </ng-template>
        </div>
        <ng-container *ngIf="breadcrumbs && breadcrumbs.length > 0">
          <ul class="breadcrumbs">
            <li *ngFor="let crumb of firstCrumbs" (click)="breadcrumbClick(crumb)" class="first-crumbs">{{crumb.label | transloco}}<span class="seperator">></span></li>
            <li *ngIf="lastCrumb" class="last-crumb">{{lastCrumb.label | transloco}}</li>
          </ul>
        </ng-container>
        <div class="actions-panel">
          <ng-container *ngIf="checkboxes && checkboxes.length > 0">
            <mcp-checkbox-list class="checkboxes" [items]="checkboxes" (changeEvent)="checkboxClick($event)">
            </mcp-checkbox-list>
          </ng-container>
          <ng-container *ngIf="hasSearchField">
            <mcp-searchfield class="searchfield" (changeEvent)="searchUpdate($event)">
            </mcp-searchfield>
          </ng-container>
        </div>
        

        <ng-container *ngIf="subtitle">
          <p class="mcp-card-content-container__subtitle">{{ subtitle | transloco}}</p>
        </ng-container>
      </div>
      <ng-container *ngIf="isLoading">
        <mcp-loader></mcp-loader>
      </ng-container>
    </div>
    <div class="mcp-card-content-container__separator"></div>
    <div class="h-100 mcp-card-content-container__main">
      <ng-content></ng-content>
    </div>
  </div>
</div>
