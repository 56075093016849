import {
  ButtonComponent,
  CardComponent,
  CardCustomTitleComponent,
  InfoButtonComponent,
  ListViewComponent,
  LoaderComponent,
  PressReleaseButtonComponent,
  SingleListItemComponent,
  VerticalSliderComponent,
  RadioItemListComponent,
  SearchFieldComponent,
  FileSelectComponent
} from './components';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GatePositionStatusButtonComponent } from '@shared/components/gate-position-status-button/gate-position-status-button.component';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StatusIconComponent } from '@shared/components/status-icon/status-icon.component';
import { TranslocoModule } from '@ngneat/transloco';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { MatIconModule } from '@angular/material/icon';
import { StatusSignalComponent } from './components/status-signal/status-signal.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { MatOptionModule } from '@angular/material/core';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LeaveButtonComponent } from '@app/features/main/components';
import { DragnDropDirective } from './directives/dragndrop.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AreYouSureDialogComponent } from '@app/features/main/components/are-you-sure-dialog/are-you-sure-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RemoteControlComponent } from '@app/core/components/remote-control/remote-control.component';
import { MatSliderModule } from '@angular/material/slider';

const imports = [
  CommonModule,
  MatIconModule,
  TranslocoModule,
  FormsModule,
  MatInputModule,
  MatSelectModule,
  MatOptionModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatDialogModule,
  MatSliderModule
];
const declarations = [
  ListViewComponent,
  SingleListItemComponent,
  CardComponent,
  CardCustomTitleComponent,
  InfoButtonComponent,
  ButtonComponent,
  LoaderComponent,
  PressReleaseButtonComponent,
  VerticalSliderComponent,
  StatusIconComponent,
  GatePositionStatusButtonComponent,
  RadioItemListComponent,
  CheckboxListComponent,
  BackButtonComponent,
  StatusSignalComponent,
  SearchFieldComponent,
  LanguageSelectorComponent,
  LeaveButtonComponent,
  DragnDropDirective,
  FileSelectComponent,
  AreYouSureDialogComponent,
  RemoteControlComponent
];
const exports = [...declarations, TranslocoModule, HttpClientModule];

@NgModule({
  imports: [imports, FormsModule],
  declarations,
  exports
})
export class SharedModule {}
