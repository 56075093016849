import {
  AbstractMcpDeviceService,
  McpActiveProcessService
} from './core/services';
import {
  ActivatedRoute,
  Data,
  Event,
  NavigationEnd,
  Router
} from '@angular/router';
import packageInfo from '../../package.json';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, filter, map, mergeMap, tap } from 'rxjs';

import { ActiveProcessEnum } from './core/enums';
import { ErrorHandlingOverlayComponent } from './core/components';
import { ROUTER_CFG_PROCESS_VALUE_TO_SET } from './core/constants';
import { Breadcrumb, MenuItem } from './shared/interfaces/interfaces';
import { MENU_ITEMS } from './app.config';
import { ROUTES } from './app-routing.config';
import { LanguageService } from './shared/services/language.service';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'mcp-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(ErrorHandlingOverlayComponent)
  errorHandlingOverlayComponent: ErrorHandlingOverlayComponent;

  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  @ViewChild('drawerRight', { static: true }) drawerRight: MatDrawer;

  breadcrumbs: Breadcrumb[] = [];
  menuItems: MenuItem[] = MENU_ITEMS;
  headerIsVisible = false;
  sidebarIsVisible = false;
  sidebarRightIsVisible = false;
  hasButtons = true;
  errorOccured$: Observable<number> = this._mcpDevice
    .getErrorOccured$()
    .pipe(tap(() => this.errorHandlingOverlayComponent.show()));
  appVersion = packageInfo.version;

  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _mcpActiveProcessService: McpActiveProcessService,
    private readonly _mcpDevice: AbstractMcpDeviceService,
    private readonly _languageService: LanguageService,
    private readonly _mcpDeviceService: AbstractMcpDeviceService
  ) {
    this._mcpActiveProcessService
      .getCurrentlyActiveProcess$()
      .subscribe((activeProcess) =>
        console.log(`MCPAPP::Process := ${activeProcess}`)
      );
      console.log('info: ', packageInfo);
  }

  public ngOnInit(): void {
    this._languageService.init();
    this._router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        tap(this._setHeaderIsVisible.bind(this)),
        tap(this._setSidebarIsVisible.bind(this)),
        map(() => this._activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe(this._setActiveProcessBasedOnRouteData.bind(this));
  }

  public navItemClicked(item: MenuItem) {
    this._router.navigate(item.route);
  }

  public logoClicked() {
    this._router.navigate([ROUTES.MAIN]);
  }

  private _setHeaderIsVisible(event: Event): void {
    const navigationEnd = event as NavigationEnd;

    this.headerIsVisible = !['/', '/start'].includes(navigationEnd.url);
  }

  private _setSidebarIsVisible(event: Event): void {
    const navigationEnd = event as NavigationEnd;

    this.sidebarIsVisible = this.sidebarRightIsVisible = ![
      '/',
      '/start',
      '/device-prep',
      '/device-discovery',
      '/device-connected',
      '/connection-lost'
    ].includes(navigationEnd.url);
  }

  private _setActiveProcessBasedOnRouteData(data: Data): void {
    const transformedData: {
      [ROUTER_CFG_PROCESS_VALUE_TO_SET]?: ActiveProcessEnum;
    } = data;

    if (!transformedData[ROUTER_CFG_PROCESS_VALUE_TO_SET]) {
      return;
    }

    this._mcpActiveProcessService.setCurrentlyActiveProcess(
      transformedData[ROUTER_CFG_PROCESS_VALUE_TO_SET]!
    );
  }

  public onClickLeave(): void {
    this._mcpDeviceService.clbk_disconnect();
    this.drawer.close();
    this.drawerRight.close();
  }
}
