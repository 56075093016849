import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { Breadcrumb } from '@app/shared/interfaces/interfaces';
import { getDeepCopy } from '@app/utils';
import { CardCustomTitleComponent } from '@shared/components/card/card-custom-title.component';
import { Checkbox } from '../checkbox-list/checkbox.interface';

@Component({
  selector: 'mcp-card',
  templateUrl: 'card.component.html',
  styleUrls: ['card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent implements OnChanges {
  @Input() title: string | undefined;
  @Input() subtitle: string | undefined;
  @Input() hasCloseButton = true;
  @Input() isLoading = false;
  @Input() hasBackButton = false;
  @Input() breadcrumbs: Breadcrumb[];
  @Input() checkboxes: Checkbox[];
  @Input() hasSearchField = false;

  @Input() titleTemplate: TemplateRef<CardCustomTitleComponent>;

  @Output() cardClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() breadcrumbEvent: EventEmitter<Breadcrumb> =
    new EventEmitter<Breadcrumb>();

  @Output() checkboxEvent: EventEmitter<Checkbox> =
    new EventEmitter<Checkbox>();

  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() searchEvent: EventEmitter<string> = new EventEmitter<string>();

  firstCrumbs: Breadcrumb[];
  lastCrumb: Breadcrumb | undefined;

  constructor(private readonly _location: Location) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.firstCrumbs = [];
    this.lastCrumb = undefined;
    if (this.breadcrumbs && this.breadcrumbs.length > 0) {
      this.lastCrumb = getDeepCopy(
        this.breadcrumbs[this.breadcrumbs.length - 1]
      );
      if (this.breadcrumbs.length > 1) {
        this.firstCrumbs = this.breadcrumbs.slice(
          0,
          this.breadcrumbs.length - 1
        );
      }
    }
  }

  onClickClose(): void {
    this.cardClosed.emit();
  }

  goBack() {
    this.backEvent.emit();
  }

  breadcrumbClick(crumb: Breadcrumb) {
    this.breadcrumbEvent.emit(crumb);
  }

  checkboxClick(crumb: Checkbox) {
    this.checkboxEvent.emit(crumb);
  }

  searchUpdate(searchTerm: string) {
    this.searchEvent.emit(searchTerm);
  }
}
