import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractMcpDeviceService, McpDeviceService } from '@core/services';
import { ButtonType } from '@shared/components/button/utils';
import { PositionTypeEnum } from '@core/enums';
import { DoorMovement, DoorState } from '@app/shared/enums/enums';
import { RemoteControlService } from './remote-control.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mcp-remote-control',
  templateUrl: 'remote-control.component.html',
  styleUrls: ['remote-control.component.scss']
})
export class RemoteControlComponent implements OnInit, OnDestroy {
  buttonType = ButtonType.Success;
  positionType = PositionTypeEnum;
  @Input() hasOpenButton = false;
  @Input() hasIntermediateOpenButton = false;
  @Input() hasIntermediateCloseButton = false;
  @Input() hasCloseButton = false;
  @Input() hasStopButton = false;
  @Input() description: string;
  intermediateOpenedDisabled = false;
  intermediateClosedDisabled = false;
  hasRegistered = false;
  doorState = DoorState.INTERMEDIATE_CLOSED;
  subscriptions: Subscription[] = [];

  constructor(
    private readonly _mcpDevice: AbstractMcpDeviceService,
    private remoteControlService: RemoteControlService
  ) {}

  ngOnInit() {
    if (!this.hasRegistered) {
      try {
        this.hasRegistered = true;
        const updateSub = this.remoteControlService.updateOnId.subscribe((values) => {
          const intermediateValues = values as unknown as number[];
          if (Array.isArray(values)) {
            this.intermediateOpenedDisabled = !intermediateValues[0];
            this.intermediateClosedDisabled = !intermediateValues[1];
          }
        });
        this.subscriptions.push(updateSub);
      } catch (error) {
        console.log('[RemoteControl] could not register: ', error);
      }
    }
    const doorStateSub = this.remoteControlService.updateOnDoorPosition.subscribe(state => this.doorState = state);
    this.subscriptions.push(doorStateSub);
  }

  ngOnDestroy() {
    this.remoteControlService.unregisterIds();
    this.subscriptions.forEach((item) => item.unsubscribe());
  }

  moveDoorClickHandler(positionType: PositionTypeEnum) {
    console.log('[RemoteControl] moveDoorClickHandler: ', positionType);
    switch (positionType) {
      case PositionTypeEnum.epo:
        this.writeData(DoorMovement.END_POSITION_OPEN_INSIDE);
        break;
      case PositionTypeEnum.epc:
        this.writeData(DoorMovement.END_POSITION_CLOSE);
        break;
      case PositionTypeEnum.ipo:
        this.writeData(DoorMovement.IME_POSITION_OPEN);
        break;
      case PositionTypeEnum.ipc:
        this.writeData(DoorMovement.IME_POSITION_CLOSE);
        break;
    }
  }

  writeData(doorMovement: DoorMovement) {
    this._mcpDevice.clbk_writetoid(McpDeviceService.ID_IMPULS_TARGET, [
      doorMovement
    ]);
  }

  stopClickHandler() {
    console.log('[RemoteControl] stop');
    this.writeData(DoorMovement.POSITION_STOP);
  }
}
